import React from 'react';
import Layout from '../Layout';
import "./about.scss";
import banner from "../../images/about-banner.jpg"
import SEO from '../seo';


const AboutPage = ({ location }) => {
    return (
        <Layout>
            <SEO title="Chimaya Mission x TMM | The Vision" description="Learn more about the vision, goals and initiatives of the Chinmaya Mission." keywords="Chinmaya Mission, Tata Mumbai Marathon, Central Chinmaya Mission Trust, CCMT, fundraising, fundraising website"
                img={`https://${location.host}/link-preview.jpg`} />
            <div className='aboutus'>
                <h1>About</h1>
                <div className="divider"></div>
                <img className='aboutus__banner' src={banner}></img>

                {/* <div className='quote'>
                    <em>“When the time of judgment comes, we shall not be asked what we have read but what we have done.”</em>
                    - Pujya Gurudev Swami Chinmayananda Ji
                </div> */}

                <h4>Our Vision</h4>
                <p>
                    <em> The inner transformation of individuals through knowledge of Vedanta, spiritual practices and service to society, resulting in a happy world around them.</em>
                </p>

                <h4> Our Mission</h4>
                <p><em> To provide to individuals from any background, the wisdom of Vedanta and the practical means for spiritual growth and happiness, enabling them to become positive contributors to the society.</em>
                </p>

                <p>Chinmaya Mission was formed in 1953 under the guidance of Pujya Gurudev Swami Chinmayananda Ji to provide the divine knowledge of Vedanta and serve the society through positive change.
                    At the Chinmaya Mission, our goal is to “give maximum happiness to the maximum number for the maximum time.”<br /><br />
                    The Tata Mumbai Marathon is not just another event for us, but a reflection of our never-ending quest to cross milestones in the interest of humanity.<br /><br />
                    Since its formation, Chinmaya Mission has been working in various parts of the country to provide selfless service in the fields of education, rural development and women empowerment. Funds raised will be used towards the multi-fold implementation of key initiatives in these areas.<br /><br />
                    It symbolizes the miles we have yet to go, as well as the heights already scaled.<br /><br />
                    By endorsing our efforts, you become a part of an initiative that has a far reaching impact on a larger canvas.<br /><br />
                    Reach out today and change the lives of millions. <br /><br />
                    <a href='https://www.unitedwaymumbai.org/ngo-1317' className='btn'>View the Fundraiser</a>

                </p>
            </div>
        </Layout >
    );
}

export default AboutPage;
